const D3Line = {}

D3Line.create = (el, data, configuration) => {
    // D3 Code to create the chart
}

D3Line.update = (el, data, configuration, chart) => {
    // D3 Code to update the chart
}

D3Line.destroy = () => {
    // Cleaning code here
}

export default D3Line
