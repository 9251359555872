import React from 'react';

import './Ruler.scss';

function Ruler(){
  return (
    <div class='ruler'>
          <div class='cm'>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          </div>
          <div class='cm'>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          </div>
          <div class='cm'>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          </div>
          <div class='cm'>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          </div>
          <div class='cm'>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          </div>
          <div class='cm'>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          </div>
          <div class='cm'>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          </div>
          <div class='cm'>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          </div>
          <div class='cm'>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          </div>
          <div class='cm'>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          <div class='mm'></div>
          </div>
          <div class='cm'></div>
          </div>
  )
}

export default Ruler;