import React, { Component } from 'react'
import './Nav.scss'


class Nav extends Component {
    constructor(){
        super()
        this.state = {
        
        }
    }

  

    render (){
   
        return (
              
            <div id='nav-container'>
                
            </div>
            
        );
    }
}

export default Nav;
